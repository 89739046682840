import axios from 'axios';
import {message} from 'antd';
import user from 'store/user';
import TokenUtils from 'common/js/token';

// eslint-disable-next-line no-undef
axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

// window.deviceMallUrl = 'http://10.88.89.116:8514';
window.deviceMallUrl = 'http://123.207.88.210:8514';
window.deviceMallImg = 'http://pub-res.zdjcyun.com';
// window.deviceMallImg = '';
window.deviceMallLogo = 'http://pub-res.zdjcyun.com/device-mall/logo/';
window.Psq_ImgUrl = 'http://static.zdjcyun.com';


const TOKEN_NAME = "Authorization";

axios.interceptors.request.use(function (config) {
  let customConfig = {};
  if (config) {
    customConfig = {...config};
  }
  if (!customConfig.doNotTakeToken) {
    //需要携带token
    if (!customConfig.headers) {
      customConfig.headers = {};
    }
    if (!customConfig.headers[TOKEN_NAME]) {
      // 从cookie中获取token
      customConfig.headers[TOKEN_NAME] = TokenUtils.getToken();
    }
  }
  return customConfig;
}, function (error) {
  message.error('请求错误');
  return Promise.reject(error);
});

let flag = true;
let timer;
axios.interceptors.response.use(function (res) {
  const {code} = res.data;
  // 无权访问
  if (code === 100) {
    if (flag) {
      message.error('长时间未操作,请重新登录');
      user.isLogin = false;
      window.localStorage.setItem('token', null);
      window.location = '/login';

      flag = false;
      clearTimeout(timer);
      timer = setTimeout(() => {
        flag = true
      }, 3000);
    }
  }
  ;
  //刷新token
  if (res.headers.authorization !== undefined) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.headers.authorization;
    window.localStorage.setItem('token', 'Bearer ' + res.headers.authorization);
  }
  ;
  return res;
}, function (err) {
  if (flag) {
    message.error('系统异常');

    flag = false;
    clearTimeout(timer);
    timer = setTimeout(() => {
      flag = true
    }, 3000);
  }
  return Promise.reject(err);
});
