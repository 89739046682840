import React, { Component, lazy, Suspense } from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react';
import axios from 'axios';
import TokenUtils from 'common/js/token'
import PrivateRoute from 'component/Auth/Auth1';
import user from 'store/user';
// import Login from 'page/Login/Login';
// import Register from 'page/Login/Register';
// import PublicHome from 'page/home/publichome/index';

// import ServiceField from "./home/service-field";
// import ClassicCase from "./home/classic-case";
// import PlatformCapability from "./home/platform-capability";
// import DeviceList from 'page/home/deviceMall/deviceList';
// import DeviceInfo from 'page/home/deviceMall/deviceInfo';
// import DeviceMap from 'page/home/deviceMall/deviceMap';

// import BigData from 'page/home/bigdata/bigdata';
// import Home from 'page/home/loging/index';
// import AlarmSimple from 'page/alarmsimple/alarmsimple';
// import Layout from 'page/Layout/Layout';
import ScrollToTop from "../component/ScrollToTop";
// import DeviceMangement from "./home/device-management";
// import VideoAbility from 'page/home/video-ability';
// import DataVisualization from "./home/data-visualization";

/* 待测试 */
const Login = lazy(_ => import('page/Login/Login'));
const Register = lazy(_ => import('page/Login/Register'));
const PublicHome = lazy(_ => import('page/home/publichome/index'));
const BigData = lazy(_ => import('page/home/bigdata/bigdata'));
const Home = lazy(_ => import('page/home/loging/index'));
const AlarmSimple = lazy(_ => import('page/alarmsimple/alarmsimple'));
const Layout = lazy(_ => import('page/Layout/Layout'));

const ServiceField = lazy(_ => import("./home/service-field"));
const ClassicCase = lazy(_ => import("./home/classic-case"));
const PlatformCapability = lazy(_ => import("./home/platform-capability"));
const DeviceList = lazy(_ => import('page/home/deviceMall/deviceList'));
const DeviceInfo = lazy(_ => import('page/home/deviceMall/deviceInfo'));
const DeviceMap = lazy(_ => import('page/home/deviceMall/deviceMap'));

@withRouter
@observer
class Root extends Component {
  render() {
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <ScrollToTop />
        <Switch>
          <Route path='/login' component={Login} />
          <Route path='/register' component={Register} />
          <Route path='/publichome' component={PublicHome} />

          <Route path='/service-field/:field' component={ServiceField} />
          <Route path='/classic-case/:type' component={ClassicCase} />
          <Route path='/platform-capability/:field' component={PlatformCapability} />
          <Route path='/deviceList' component={DeviceList} />
          <Route path='/deviceInfo/:id' component={DeviceInfo} />
          <Route path='/deviceMap' component={DeviceMap} />

          <Route path='/bigdata' component={BigData} />
          <PrivateRoute path='/home' component={Home} />
          <PrivateRoute path='/alarmsimple' component={AlarmSimple} />
          <PrivateRoute path='/project' component={Layout} />
          <Redirect from='/' to='/publichome' />
        </Switch>
      </Suspense>
    );
  }

  componentWillMount() {
    this.isLocalLogin();
  }

  isLocalLogin() {
    // 尝试从cookie读取
    const token = TokenUtils.getToken();
    if (token) {
      user.isLogin = true;
    }
  }
}

export default Root;
