import Cookies from 'js-cookie';


const isDev = process.env.NODE_ENV === 'development';
// console.log(process.env.NODE_ENV);
const TOKEN_KEY = "token";
// 设置域名
const DOMAIN = ".zdjcyun.com";
// 两个小时过期
const expireDays = 2 / 24;

/**
 * Token管理工具，这里使用Cookie存储
 */
class TokenUtils {

  static getToken() {
    return Cookies.get(TOKEN_KEY);
  }

  static setToken(token) {
    Cookies.set(TOKEN_KEY, token, isDev ? {expires: expireDays} : {domain: DOMAIN, expires: expireDays});
  }

  static removeToken() {
    Cookies.remove(TOKEN_KEY, isDev ? undefined : {domain: DOMAIN});
  }
}


export default TokenUtils;
