import React, { Component } from 'react';
import { Route, withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import user from 'store/user';

@observer
class PrivateRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: user.isLogin
    }
  }

  componentWillMount() {
    if (!user.isLogin) {
      const { history } = this.props;
      setTimeout(() => {
        history.replace("/login");
      }, 1000);
    }
  }

  render() {
    let { component: Component, ...rest } = this.props;
    return user.isLogin ?
      <Route {...rest} render={props => <Component {...props} />} />
      :
      <p>请先登录...</p>
  }
}

export default withRouter(PrivateRoute);
